import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from "rxjs/operators";
import "rxjs/add/operator/toPromise";
import { overrideConfigFromLocalStorage } from "@bluegrace/bs.npmcommon";

export type TStartupData = {
  authApiUrl: string;
  environment: string;
  ratingApiUrl: string;
  shipmentApi: string;
  launchDarklyKey: string;
  // feature flags:
  enableFTLRates: string;
  enableFuelDay: string;
  launchDarklyFlags: LDFlagSet;
};

@Injectable({
  providedIn: "root",
})
export class StartupService {
  // To override the configs below, go to the browser console and run "bgEnvConfigOverrides.enable()"
  // This will enable the EnvConfigOverride UI at the bottom right corner of the screen
  private _startupData: TStartupData = {
    authApiUrl: "https://identity-qa.myblueship.com/",
    environment: "dev",
    ratingApiUrl: "https://rating-qa.myblueship.com",
    shipmentApi: "https://shipmentapi-qa.myblueship.com/api/",
    launchDarklyKey: "663011b2823ab90fab21d205",
    // feature flags:
    enableFTLRates: "true",
    enableFuelDay: "true",
    launchDarklyFlags: {},
  };
  private _initComplete = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient
  ) {}

  load(): Promise<TStartupData> {    

    return this.http
      .get("/config")
      .pipe(
        map((res) => {
          const jsonObject = typeof res === 'string' ? JSON.parse(res) : res;
          return jsonObject as TStartupData;
        })
      )
      .toPromise()
      .catch(() => {
        Promise.resolve(this._startupData);    
        this._initComplete.next(true);
      })
      .then((data: TStartupData) => {
        if (!data) {
          return this._startupData;
        }

        this._startupData = overrideConfigFromLocalStorage(
          data,
          ["environment"],
          "Bluelight"
        );
        console.log("startupData", this._startupData);
        this._initComplete.next(true);
        return this._startupData;
      });
  }

  get startupData() {
    return this._startupData;
  }
  get ratingApiUrl() {
    return this._startupData.ratingApiUrl;
  }
  get shipmentApiUrl() {
    return this._startupData.shipmentApi;
  }
  get authAPIUrl() {
    return this._startupData.authApiUrl;
  }
  get appEnvironment() {
    return this._startupData.environment;
  }
  // feature flags:
  get enableFTLRates() {
    return JSON.parse(this._startupData.enableFTLRates);
  }
  get enableFuelDay() {
    return JSON.parse(this._startupData.enableFuelDay);
  }
  get launchDarklyFlags() {
    return this._startupData.launchDarklyFlags;
  }
  set launchDarklyFlags(flags) {
    this._startupData.launchDarklyFlags = flags;
  }
  get initComplete$(): Observable<boolean> {
    return this._initComplete.asObservable();
  }
}
